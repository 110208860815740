<template>
  <v-container id="data-tables-view" fluid tag="section">
    <v-col class="text-right">
      <!-- <app-btn v-if="canViewRole" class="mx-2" @click="dialogRoles = true">
        <v-icon>mdi-account-plus-outline</v-icon> Roles
      </app-btn> -->
      <app-btn v-if="canCreateUser" @click="dialog = true">
        <v-icon>mdi-account-plus-outline</v-icon> Add New User
      </app-btn>
    </v-col>
    <material-card
      icon="mdi-account-group"
      icon-small
      color="primary"
      title="Users"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="users"
          :search.sync="search"
          multi-sort
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:[`item.role`]="{ item }">
            <div v-if="item.role">
              {{ item.role }}
            </div>
            <div v-else>--</div>
          </template>
          <template v-slot:top>
            <password-reset
              :username="passwordResetUsername"
              :isDialog="passwordResetDialog"
              @onClose="passwordResetDialog = false"
            />
            <roles
              :show="dialogRoles"
              :roles="roles"
              @close="dialogRoles = false"
            />
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="UserForm">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.userName"
                            label="Username"
                            :disabled="editedIndex > -1"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.firstName"
                            label="First Name"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.middleName"
                            label="Middle Name"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.lastName"
                            label="Last Name"
                          />
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.phoneNumber"
                            label="Phone Number"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <div><label>Date of Birth</label></div>
                          <v-date-picker
                            v-model="editedItem.dateOfBirth"
                          ></v-date-picker>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.postcode"
                            label="Postcode"
                            v-if="editedIndex < 0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.state"
                            :items="states"
                            label="State"
                            v-if="editedIndex < 0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.unitNumber"
                            label="Unit Number"
                            v-if="editedIndex < 0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.streetNumber"
                            label="Street Number"
                            v-if="editedIndex < 0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.streetName"
                            label="Street Name"
                            v-if="editedIndex < 0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.city"
                            label="Suburb"
                            v-if="editedIndex < 0"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-if="editedIndex < 0"
                            v-model="editedItem.password"
                            label="Password"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-if="editedIndex < 0"
                            v-model="editedItem.confirmPassword"
                            label="Confirm Password"
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <!-- <v-col v-if="canViewRole" cols="12">
                          <v-select
                            v-model="editedItem.role"
                            :items="roles"
                            item-text="name"
                            item-value="name"
                            label="Role"
                          />
                        </v-col> -->
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              medium
              color="error"
              class="mr-2"
              @click="resetPassword(item)"
            >
              mdi-lock-reset
            </v-icon>
            <v-icon
              class="mr-2"
              medium
              color="success"
              v-if="!item.lockoutEnabled"
              @click="activateUser(item.id, false)"
              >mdi-check-circle
            </v-icon>
            <v-icon
              class="mr-2"
              medium
              color="error"
              v-else
              @click="activateUser(item.id, true)"
              >mdi-close-circle
            </v-icon>
            <v-icon
              v-if="canEditUser"
              medium
              color="info"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              v-if="canDeleteUser"
              medium
              color="error"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import UserDataService from "@/services/UserDataService";
import PasswordReset from "./components/resetPassword.vue";
import Roles from "./components/Roles.vue";
import moment from "moment";

export default {
  name: "DataTablesView",
  components: { Roles, PasswordReset },
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    rules: {
      required: (value) => !!value || "Required.",
      length15: (value) => value.length <= 15 || "Maximum 15 characters.",
    },
    dialog: false,
    dialogDelete: false,
    dialogRoles: false,
    passwordResetDialog: false,
    passwordResetUsername: "",
    headers: [],
    users: [],
    roles: [],
    states: ["NSW", "ACT", "QLD", "VIC", "NT", "WA", "SA"],
    editedIndex: -1,
    editedItem: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      dateOfBirth: moment().format("YYYY-MM-DD"),
      password: "",
      role: "",
    },
    defaultItem: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      dateOfBirth: moment().format("YYYY-MM-DD"),
      password: "",
      role: "",
    },
    search: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New User" : "Edit User";
    },
    canCreateUser() {
      // return this.permissions.includes("Permissions.Users.Create");
      return true;
    },
    canEditUser() {
      //return this.permissions.includes("Permissions.Users.Edit");
      return true;
    },
    canDeleteUser() {
      //return this.permissions.includes("Permissions.Users.Delete");
      return true;
    },
    canViewRole() {
      //return this.permissions.includes("Permissions.Roles.ViewAll");
      return true;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/User").then((response) => {
        this.users = response.data;
      });
      UserDataService.getAllRoles().then((response) => {
        this.roles = response.data;
      });

      if (this.canEditUser || this.canDeleteUser) {
        this.headers = [
          {
            text: "Username",
            align: "start",
            sortable: false,
            value: "userName",
          },
          { text: "First Name", value: "firstName" },
          { text: "Middle Name", value: "middleName" },
          { text: "Last Name", value: "lastName" },
          { text: "Date of Birth", value: "dateOfBirth" },
          { text: "Email", value: "email" },
          { text: "Phone Number", value: "phoneNumber" },
          { text: "Role", value: "roles" },
          { text: "Actions", align: "end", value: "actions", sortable: false },
        ];
      } else {
        this.headers = [
          {
            text: "Username",
            align: "start",
            sortable: false,
            value: "userName",
          },
          { text: "First Name", value: "firstName" },
          { text: "Last Name", value: "lastName" },
          { text: "Email", value: "email" },
          { text: "Role", value: "role" },
        ];
      }
    },
    resetPassword(item) {
      this.passwordResetUsername = item.userName;
      this.passwordResetDialog = true;
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/User/${this.editedItem.id}`)
        .then((response) => {
          if (response.data.success) {
            this.users.splice(index, 1);
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {});
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    activateUser(userId, isActive) {
      if (isActive) {
        this.$http
          .get("/User/ActivateUser", { params: { id: userId } })
          .then((response) => {
            if (response.data.success) {
              let index = this.users.findIndex((x) => x.id === userId);
              if (index > -1) {
                let user = this.users[index];
                user.lockoutEnabled = false;
                this.users.splice(index, 1, user);
              }
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch(() => {});
      } else {
        this.$http
          .get("/User/Deactivate", { params: { id: userId } })
          .then((response) => {
            if (response.data.success) {
              let index = this.users.findIndex((x) => x.id === userId);
              if (index > -1) {
                let user = this.users[index];
                user.lockoutEnabled = true;
                this.users.splice(index, 1, user);
              }
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch(() => {});
      }
    },
    save() {
      if (this.$refs.UserForm.validate()) {
        if (this.editedIndex > -1) {
          const data = this.editedItem;
          const index = this.editedIndex;
          let userId = this.editedItem.id;
          data.id = this.editedItem.profileId;
          this.$http
            .put(`/User/UpdateUser/${userId}`, data)
            .then((response) => {
              if (response.data.success) {
                this.$set(this.users, index, data);
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            });
        } else {
          this.$http
            .post("/Authentication/register-admin", this.editedItem)
            .then((response) => {
              if (response.data.status === "Success") {
                this.$toast.success(response.data.message, "Success", {
                  position: "topRight",
                });
                this.users.unshift(response.data.data);
              } else {
                this.$toast.error(response.data.message, "Error", {
                  position: "topRight",
                });
              }
            });
        }
        this.close();
      }
    },
  },
};
</script>
