<template>
  <v-dialog v-model="dialog" max-width="850px">
    <v-card>
      <v-card-title>
        <span class="headline">Reset Password</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="bookingForm">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="password" label="New password" />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="confirmPassword"
                  label="Confirm password"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "PasswordReset",
  props: ["isDialog", "username"],
  data: () => ({
    dialog: false,
    password: "",
    confirmPassword: "",
  }),

  watch: {
    isDialog(val) {
      this.dialog = val;
    },
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.password = "";
      this.confirmPassword = "";
      this.$emit("onClose");
    },
    save() {
      this.$http
        .post("/Authentication/ResetPassword", {
          username: this.username,
          password: this.password,
          confirmPassword: this.confirmPassword,
        })
        .then((response) => {
          if (response.data.success) {
            this.$toast.success(response.data.message, "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((err) => {});
      this.close();
    },
  },
};
</script>